// 侧边栏配置
const navList = [
  {
    title: '认证管理',
    path: '/index',
    routeName: 'index',
    icon: 'el-icon-s-check',
    children: [
      {
        title: '个人认证',
        path: '/personalaudit',
        routeName: 'personalaudit'
      },
      {
        title: '机构认证',
        path: '/enterpriseaudit',
        routeName: 'enterpriseaudit'
      }
    ]
  },
  {
    title: '作品审核',
    icon: 'el-icon-picture-outline',
    routeName: 'worksVideos',
    children: [
      {
        title: '视频审核',
        path: '/worksVideos',
        routeName: 'worksVideos'
      },
      {
        title: '图片审核',
        path: '/worksImages',
        routeName: 'worksImages'
      }
    ]
  },
  {
    title: '配置管理',
    icon: 'el-icon-location',
    routeName: 'dispose',
    children: [
      {
        title: '首页配置',
        path: '/dispose',
        routeName: 'dispose'
      }
    ]
  }
]
export default navList
