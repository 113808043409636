<template>
  <div class="header">
    <div class="header_left">
      {{$route.meta.title}}
    </div>
    <div class="header_right">
      <el-popover placement="bottom"
                  trigger="hover"
                  :width="90">
        <div class="header_img">
          <el-button type="danger"
                     @click="logOut">退出登录</el-button>
        </div>
        <template #reference>
          <el-avatar :src="userInfo.user_face || 'https://cdn.hsuanyuen.com/Fe-public/inshoot-file/user/default-user.png'"
                     class="user-avatar"></el-avatar>
          <HsAvatar :url="userInfo.user_face"></HsAvatar>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
export default {
  emits: [],
  components: {
  },
  props: {

  },
  setup (props, context) {
    const { proxy } = getCurrentInstance()
    const router = useRouter()
    const data = reactive({ // 定义变量
      userInfo: JSON.parse(localStorage.getItem('HSUC_LOGIN_INFO'))
    })
    // watch(props.tabcurrent, (val) => {
    //   data.tab = val
    // })
    const logOut = () => {
      proxy.$confirm('是否确定退出登录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        layout()
      }).catch(() => {
      })
    }

    /**
     * 退出登录
     */
    const layout = async () => {
      try {
        await proxy.$api.adminlogout()
        localStorage.removeItem('HSUC_LOGIN_INFO')
        router.push('/login')
      } catch (err) {
        return proxy.$message.error(err)
      }
    }

    return {
      ...toRefs(data),
      logOut
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 100%;
  .header_left {
    margin-left: 20px;
  }
  .header_right {
    margin-right: 25px;
  }
}
</style>
