
<template>
  <div>
    <el-container>
      <el-aside>
        <asideBar></asideBar>
      </el-aside>
      <el-container>
        <el-header>
          <navBar></navBar>
        </el-header>
        <el-main>
          <appMain></appMain>
        </el-main>
        <!-- <el-footer>
         <footerBar></footerBar>
      </el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import asideBar from './components/asideBar'
import navBar from './components/navBar'
import appMain from './components/appMain'
// import footerBar from './components/footerBar'
export default {
  components: {
    asideBar,
    navBar,
    appMain
    // footerBar
  }
}

</script>

<style scoped>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
}

.el-aside {
  background-color: #ffffff;
  color: #333;
  width: 210px !important;
  height: 100vh;
  text-align: center;
  line-height: 200px;
  box-shadow: 0px 20px 7px 0px rgba(3, 3, 3);
}

.el-main {
  /* background-color: #E9EEF3; */
  /* color: #333; */
  /* text-align: center; */
  /* line-height: 160px; */
  padding: 0;
  background: #f7f7f7;
  height: calc(100vh - 60px);
  overflow: auto;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>
