<template>
  <div class="aside-wrapper">
    <div class="logo">
      <span class="logo_img"></span>
    </div>
    <el-menu :default-active="$route.name"
             unique-opened
             router>
      <component v-for="(item,index) in navList"
                 :key="index"
                 :index="item.routeName"
                 :is="(item.children&&item.children.length>0)?'el-submenu':'el-menu-item'">
        <template #title>
          <i :class="[item.icon]"
             class="iconfont"></i>
          <span>{{item.title}}</span>
        </template>
        <template v-if="item.children&&item.children.length>0">
          <el-menu-item class="menu-item"
                        v-for="(secItem,secIndex) in item.children"
                        :key="secIndex"
                        :index="secItem.routeName">
            <template #title>
              <span>{{secItem.title}}</span>
            </template>
          </el-menu-item>
        </template>
      </component>
    </el-menu>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import navList from '@/config/asideBar/asideBar'
export default defineComponent({
  setup (props, context) {
    // 页面挂载
    onMounted(() => {

    })
    // const handleOpen = (key, keypath) => {
    //   console.log(key)
    //   console.log(keypath)
    // }
    return { navList }
  }
})
</script>

<style lang="scss" scoped>
.aside-wrapper {
  text-align: left;
  height: 100%;
  width: 209px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.05);
  .logo {
    height: 60px;
    width: 80%;
    line-height: 60px;
    text-align: center;
    margin: auto;
    padding-top: 25px;
    .logo_img {
      display: inline-block;
      width: 100%;
      height: 100%;
      background: url("../../assets/img/inshoot-logo.png") no-repeat;
      background-size: 100%;
    }
  }
}
.aside-wrapper::v-deep(.el-menu) {
  border-right: none;
}
.aside-wrapper::v-deep(.menu-item) {
  text-align: center;
}
</style>
